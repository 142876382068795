import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextfield = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
  },
}));

export default StyledTextfield;
